import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import ModalVideo from 'components/ModalVideo'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { title, items } = content

  return (
    <Inview className={styles.videos}>
      <div className='container'>
        {title && (
          <div className='row'>
            <div className='col-12'>
              <h1>{title}</h1>
            </div>
          </div>
        )}
        <div className={styles.videosContainer}>
          {items.map((item, i) => (
            <Item content={item} key={i} locale={locale} i={i} />
          ))}
          <div className={styles.videoItem} />
          <div className={styles.videoItem} />
        </div>
      </div>
    </Inview>
  )
}

const Item = ({ content, locale, i }) => {
  const { title, image, videoSlug } = content
  return (
    <div
      className={`${styles.videoItem} in in-fade-in-up-515 stagger-${i * 100}`}
    >
      <div className={styles.video}>
        <a>
          <ImageWrap image={image} />
          <i className='fal fa-play'></i>
          <ModalVideo content={{ videoSlug }} locale={locale} />
        </a>
      </div>
      <h2>{title}</h2>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridVideosEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment GridVideosZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
